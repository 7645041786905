import axiosServices from 'utils/axios';

export const GetPropertiesData = async (q?: string) => {
    try {
        const response = await axiosServices.get(`/properties/allProperties?${q}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const DownloadPropertiesList = async (q?: string) => {
    try {
        const response = await axiosServices.get(`/properties/allProperties?${q}`, {
            responseType: 'arraybuffer'
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const GetPropertiesDetailsById = async (id: string) => {
    try {
        const response = await axiosServices.get(`/properties/property-details/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const DeletePropertyById = async (id: string) => {
    try {
        const response = await axiosServices.delete(`/properties/delete-property/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};
