import axiosServices from 'utils/axios';

export const GetGlobalSearchData = async (query?: string) => {
    try {
        const response = await axiosServices.get(`/search?${query}`);
        return response;
    } catch (error) {
        return error;
    }
};
