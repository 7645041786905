import { createSlice } from '@reduxjs/toolkit';
import { agencyDetailsType, agencyType } from './types';
import { PropertyDetailsType } from 'views/agents/Components/AgentsProfile/Financial/interface';

export const initialState: agencyDetailsType = {
    agenciesList: [],
    isLoading: false,
    selectedAgency: {} as agencyType,
    propertiesList: [],
    agentsList: [],
    financialsList: {} as { [key: string]: any },
    isDeleteModalOpen: false,
    agencyFormIsLoading: false,
    page: 1,
    pageSize: 12,
    agencySettingTab: 0,
    total: 0,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    search: '',
    //financials pagination elements
    financialsListLoading: false,
    finPage: 1,
    finPageSize: 12,
    finTotal: 0,
    finSortBy: 'createdAt',
    finSortOrder: 'asc',
    selectedFinancialProperty: {} as PropertyDetailsType
};

const agencies = createSlice({
    name: 'AgencyState',
    initialState,
    reducers: {
        setAgenciesList(state, action) {
            state.agenciesList = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setSelectedAgency(state, action) {
            state.selectedAgency = action.payload;
        },
        setToggleDeleteModal(state, action) {
            state.isDeleteModalOpen = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setTotal(state, action) {
            state.total = action.payload;
        },
        setSortBy(state, action) {
            state.sortBy = action.payload;
        },
        setSearch(state, action) {
            state.search = action.payload;
        },
        setPropertiesList(state, action) {
            state.propertiesList = action.payload;
        },
        setAgentsList(state, action) {
            state.agentsList = action.payload;
        },
        setAgencyFormIsLoading(state, action) {
            state.agencyFormIsLoading = action.payload;
        },
        setAgencySettingTab(state, action) {
            state.agencySettingTab = action.payload;
        },
        setFinancialsListById(state, action) {
            state.financialsList = action.payload;
        },
        setFinPage(state, action) {
            state.finPage = action.payload;
        },
        setFinPageSize(state, action) {
            state.finPageSize = action.payload;
        },
        setFinTotal(state, action) {
            state.finTotal = action.payload;
        },
        setFinSortBy(state, action) {
            state.finSortBy = action.payload;
        },
        setFinSortOrder(state, action) {
            state.finSortOrder = action.payload;
        },
        setSelectedFinancialProperty(state, action) {
            state.selectedFinancialProperty = action.payload;
        },
        setFinancialsListLoading(state, action) {
            state.financialsListLoading = action.payload;
        },
        setSortOrder(state, action) {
            state.sortOrder = action.payload;
        }
    }
});

export const { actions: AgenciesReducerAction, name: AgenciesReducerName, reducer: AgenciesReducer } = agencies;
