import { useEffect, useState } from 'react';
import { gridSpacing } from 'store/constant';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import * as yup from 'yup';
// material-ui
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import InfoIcon from '@mui/icons-material/Info';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { DropBoxIcon } from 'ui-component/Icons';
import DropboxChooser from 'react-dropbox-chooser';
import { useDispatch, useSelector } from 'react-redux';
import { documentPropertiesListSelector, documentUnitsListSelector, selectedDocumentSelector } from 'store/documents/selector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import routeConstant from 'routes/routeConstant';
import { agenciesListSelector, agentsListByAgencySelector } from 'store/agencies/selector';
import { ClientsAutocompleteListSelector, SelectedPropertyUnitSelector } from 'store/properties/selector';
import { DocumentsSagaAction } from 'store/documents/saga';
import { AgenciesSagaAction } from 'store/agencies/saga';

const APP_KEY = 'gyas8mkefhspeus';

const DocumentsNameTab = () => {
    const [fileNames, setFileNames] = useState<any>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { pathname } = useLocation();
    const selectedUnit = useSelector(SelectedPropertyUnitSelector);

    const agenciesList = useSelector(agenciesListSelector);
    const clientsList = useSelector(ClientsAutocompleteListSelector);
    const propertiesList = useSelector(documentPropertiesListSelector);
    const agentsList = useSelector(agentsListByAgencySelector);
    const unitsList = useSelector(documentUnitsListSelector);
    const validationSchema = yup.object().shape({
        name: yup.string().trim().required('Document Name is required'),
        description: yup.string().trim().required('Description is required'),
        category: yup.string().trim().required('Category is required'),
        dropbox_info: yup
            .object()
            .shape({
                id: yup.string().trim().required('Document is required'),
                name: yup.string().trim().required('Document is required'),
                link: yup.string().trim().required('Document is required')
            })
            .required('Document is required')
    });
    const documentsDetails = useSelector(selectedDocumentSelector);
    const {
        register,
        setValue,
        reset,
        handleSubmit,
        formState: { errors, dirtyFields },
        watch,
        control
    } = useForm({ resolver: yupResolver(validationSchema), defaultValues: documentsDetails && documentsDetails });

    const onSuccess = async (files: any) => {
        setFileNames(files);
    };

    useEffect(() => {
        (dirtyFields.property_id || watch('property_id')) &&
            dispatch(DocumentsSagaAction.fetchDocumentUnitsByPropertyId(watch('property_id')));
    }, [watch('property_id')]);

    useEffect(() => {
        (dirtyFields.agency_id || watch('agency_id')) && dispatch(AgenciesSagaAction.getAgentsByAgencyId(watch('agency_id')));
    }, [watch('agency_id')]);

    useEffect(() => {
        const { property, agency, agent, units, client, access, name, date } = documentsDetails;
        if (documentsDetails) {
            reset({
                ...documentsDetails,
                name: name,
                is_document_public: access,
                property_id: property,
                agency_id: agency,
                agent_id: agent,
                client_id: client,
                unit_ids: units,
                creation_date: date ?? new Date()
            });
        }
    }, [documentsDetails, selectedUnit]);

    useEffect(() => {
        if (pathname.includes('property')) {
            setValue('property_id', id);
        } else if (pathname.includes('unit')) {
            setValue('property_id', selectedUnit?.properties_id);
            setValue('unit_ids', id);
        }
    }, [id]);

    useEffect(() => {
        setValue('dropbox_info', fileNames?.length ? fileNames[0] : documentsDetails?.dropbox_info);
    }, [fileNames]);

    const onSubmit = async (values: any) => {
        const handleNavigate = () => navigate(-1);
        const { property_id, agency_id, agent_id, client_id, unit_ids, is_document_public } = values;
        const data = {
            ...values,
            property_id: Boolean(property_id) ? property_id : null,
            agency_id: Boolean(agency_id) ? agency_id : null,
            agent_id: Boolean(agent_id) ? agent_id : null,
            client_id: Boolean(client_id) ? client_id : null,
            unit_ids: Boolean(unit_ids) ? unit_ids : null,
            is_document_public: is_document_public ?? true,
            dropbox_info: fileNames?.length ? fileNames[0] : values?.dropbox_info
        };
        if (id && pathname.includes('document')) {
            await dispatch(DocumentsSagaAction.updateDocument({ values: data, handleNavigate }));
        } else if (id && !pathname.includes('documents')) {
            await dispatch(DocumentsSagaAction.addDocument({ values: data, handleNavigate }));
        } else {
            await dispatch(DocumentsSagaAction.addDocument({ values: data, handleNavigate }));
        }
    };
    const selectArray = [
        {
            id: 'client_id',
            label: 'Client',
            listData: clientsList,
            gridSize: 4
        },
        {
            id: 'agency_id',
            label: 'Agency',
            listData: agenciesList,
            gridSize: 4
        },
        {
            id: 'agent_id',
            label: 'Agent',
            listData: agentsList?.map((agent: { [key: string]: any }) => ({
                name: agent?.first_name + ' ' + agent?.last_name,
                _id: agent?._id
            })),
            isDisabled: !watch('agency_id'),
            gridSize: 4
        },
        {
            id: 'property_id',
            label: 'Property',
            listData: propertiesList?.map((property: { [key: string]: string }) => ({
                name: property?.property_name,
                _id: property?._id
            })),
            isDisabled: pathname.includes('property') || pathname.includes('unit'),
            gridSize: 6
        },
        {
            id: 'unit_ids',
            label: 'Units',
            listData:
                unitsList?.length > 0
                    ? unitsList?.map((unit: { [key: string]: string }) => ({ name: unit?.unit_name, _id: unit?._id }))
                    : [],
            isDisabled: !watch('property_id') || pathname.includes('unit'),
            gridSize: 6
        }
    ];
    const handleBack = () => {
        pathname.includes('document') ? navigate(routeConstant.documents) : navigate(-1);
    };

    return (
        <Grid display="flex" flexDirection="column" gap="30px">
            <form>
                <Grid className="document-profile-form" container spacing={{ xs: 1, sm: gridSpacing }}>
                    <Grid item display={'flex'} flexDirection={'column'} gap="10px" fontSize={'small'} xs={12}>
                        <Grid item display={'flex'} gap="5px" alignItems={'center'} fontSize={'small'} xs>
                            <Tooltip title="DropBox">
                                <IconButton style={{ padding: 0 }}>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <span>You need to be connected to Stone Capital Dropbox account</span>
                        </Grid>
                        <Grid item paddingRight={{ xs: '0px', md: '10px' }} xs={3} gap={'7px'}>
                            <DropboxChooser
                                {...register('dropbox_info')}
                                appKey={APP_KEY}
                                multiselect={false}
                                success={(files: any) => onSuccess(files)}
                            >
                                <Button variant="contained" component="label" sx={{ background: '#fafafa', color: 'black', gap: '5px' }}>
                                    <DropBoxIcon />
                                    Choose from Dropbox
                                </Button>
                            </DropboxChooser>
                            {fileNames && <Typography padding={1}>{fileNames[0]?.name}</Typography>}
                            {!!errors?.dropbox_info && <FormHelperText error>{'Document is Required'}</FormHelperText>}
                        </Grid>
                    </Grid>
                    <Grid item className="input-property-forms" xs={12} sm={6} md={6}>
                        <TextField
                            InputLabelProps={{ shrink: !!watch('name') }}
                            helperText={errors?.name?.message?.toString()}
                            error={!!errors?.name}
                            fullWidth
                            required
                            disabled={Boolean(pathname.includes('document') && id)}
                            label="Document Name"
                            {...register('name')}
                        />
                    </Grid>
                    <Grid item className="input-property-forms" xs={12} sm={6} md={6}>
                        <TextField
                            InputLabelProps={{ shrink: !!watch('description') }}
                            helperText={errors?.description?.message?.toString()}
                            error={!!errors?.description}
                            fullWidth
                            required
                            label="Description"
                            {...register('description')}
                        />
                    </Grid>
                    <Grid item className="input-property-forms" xs={12} sm={4} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="accesibility-select">Accesibility</InputLabel>
                            <Controller
                                control={control}
                                name="is_document_public"
                                render={({ field: { value } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            value={value}
                                            label={'Accesibility'}
                                            defaultValue={true}
                                            className={errors?.accesibility && 'error'}
                                            {...register('is_document_public')}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: { maxHeight: 300 } // Limit the height of the dropdown
                                                }
                                            }}
                                        >
                                            {[
                                                { name: 'Public', id: true },
                                                { name: 'Private', id: false }
                                            ].map((option: any) => (
                                                <MenuItem value={option?.id}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                        <CheckCircleRoundedIcon fontSize="small" />
                                                        {option?.name}
                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className="input-property-forms" xs={12} sm={4} md={4}>
                        <FormControl required fullWidth error={Boolean(errors?.category?.message)}>
                            <InputLabel id="category-select">Category</InputLabel>
                            <Controller
                                control={control}
                                name="category"
                                render={({ field: { value } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            value={value}
                                            label={'Category'}
                                            required={true}
                                            className={errors?.category && 'error'}
                                            {...register('category')}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: { maxHeight: 300 } // Limit the height of the dropdown
                                                }
                                            }}
                                        >
                                            {[
                                                { name: 'General', id: 'general' },
                                                { name: 'Legal', id: 'legal' },
                                                { name: 'Financial', id: 'financial' },
                                                { name: 'Development', id: 'development' },
                                                { name: 'Gallery', id: 'gallery' }
                                            ].map((option: any) => (
                                                <MenuItem value={option?.id}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                        <CheckCircleRoundedIcon fontSize="small" />
                                                        {option?.name}
                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                            <FormHelperText>{errors?.category && errors?.category?.message?.toString()}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item className="input-property-forms" xs={12} sm={4} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Creation Date"
                                reduceAnimations
                                sx={{
                                    width: '100%',
                                    borderRadius: '12px'
                                }}
                                value={dayjs(watch('creation_date')) ?? dayjs(new Date())}
                                maxDate={dayjs(new Date())}
                                format="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    newValue && setValue('creation_date', newValue?.toDate()?.toISOString(), { shouldValidate: true });
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {selectArray?.map((field) => (
                        <Grid item key={field?.id} className="input-property-forms" xs={12} sm={field?.gridSize} md={field?.gridSize}>
                            <FormControl fullWidth>
                                <InputLabel id={`${field?.id}-select`}>{field?.label}</InputLabel>
                                <Controller
                                    control={control}
                                    name={field?.id}
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                disabled={field?.isDisabled}
                                                label={field?.label}
                                                className={errors[field?.id] && 'error'}
                                                {...register(field?.id)}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: { maxHeight: 300 } // Limit the height of the dropdown
                                                    }
                                                }}
                                            >
                                                {field?.listData?.length > 0 ? (
                                                    field?.listData?.map((option: any) => (
                                                        <MenuItem value={option?._id}>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                                <CheckCircleRoundedIcon fontSize="small" />
                                                                {field?.id === 'agency_id' ? option?.agency_name : option?.name}
                                                            </div>
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem>No Options</MenuItem>
                                                )}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    ))}
                    <Grid
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        alignItems={'center'}
                        padding="24px 24px 0"
                        paddingRight="0px"
                    >
                        <Button
                            variant="outlined"
                            disabled={window.history.length === 1}
                            startIcon={<ReplyIcon className="back-icon" />}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};

export default DocumentsNameTab;
