import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState, SyntheticEvent, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Tab, Tabs, Typography } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';

import { gridSpacing } from 'store/constant';
import { tabStyle } from 'views/properties/utils';
import { TabPanel } from 'views/clients/utils';
import { documentTabsOption } from './helper';

import color from 'assets/scss/_themes-vars.module.scss';
import { a11yProps } from 'ui-component/a11yProps/a11yProps';
import DocumentsNameTab from './DocumentsNameTab';
import { AgenciesSagaAction } from 'store/agencies/saga';
import { PropertiesSagaActions } from 'store/properties/saga';
import { DocumentsSagaAction } from 'store/documents/saga';
import { DocumentsReducerAction } from 'store/documents/slice';
import './styles.scss';

const PropertyFormsTabCards = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { pathname } = useLocation();

    const [value, setValue] = useState<number>(0);
    const handleChange = (_: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(AgenciesSagaAction.fetchAllAgenciesList());
        dispatch(DocumentsSagaAction.fetchDocumentProperties());
        dispatch(PropertiesSagaActions.fetchClientsAutoComplete({ search: '' }));
        pathname?.includes('document') && id && dispatch(DocumentsSagaAction.fetchDocumentDetailsById(id));

        return () => {
            dispatch(DocumentsReducerAction.setSelectedDocument({}));
        };
    }, [dispatch]);

    return (
        <MainCard className="property-forms-header-main-card">
            <Grid container paddingRight={0} spacing={gridSpacing}>
                {pathname?.includes('document') && (
                    <Grid className="property-forms-header" item display={'flex'} width={'100%'}>
                        <Grid width={'100%'} className="property-forms-tab-container" display="flex" justifyContent={'space-between'}>
                            <Grid item className="new-property-container">
                                <Typography sx={{ fontSize: '1.25rem', fontWeight: 600, color: color.grey1000 }}>
                                    Document Settings
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            display={'flex'}
                            alignItems={'center'}
                            flexWrap={'wrap'}
                            width={'100%'}
                            justifyContent={'end'}
                            gap={'15px'}
                            paddingBottom={'25px'}
                            className="property-forms-helper-button"
                        >
                            <Tabs
                                value={value}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChange}
                                variant="scrollable"
                                sx={tabStyle(theme)}
                                className="tab-option-client-form"
                            >
                                {documentTabsOption.map((tab, index) => (
                                    <Tab
                                        className="update-sub-header-icon-button"
                                        key={index}
                                        component={Link}
                                        to="#"
                                        icon={tab.icon}
                                        iconPosition="top"
                                        label={tab.label}
                                        {...a11yProps(index)}
                                    />
                                ))}
                            </Tabs>
                        </Grid>
                    </Grid>
                )}
                <Grid className="property-forms-data-container" item width={'100%'}>
                    <TabPanel value={value} index={0}>
                        <DocumentsNameTab />
                    </TabPanel>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default PropertyFormsTabCards;
