import { createSlice } from '@reduxjs/toolkit';
import { ClientsDetailsType, countriesListType } from './types';
import { propertyClientsListDataType } from 'store/properties/types';

export const initialState: ClientsDetailsType = {
    clientsList: [],
    selectedClient: {} as propertyClientsListDataType,
    isLoading: false,
    isFinancialsListLoading: false,
    isDeleteModalOpen: false,
    countries: [] as countriesListType[],
    clientFinancials: [],
    clientsFormTab: 0,
    financialsPage: 1,
    financialsPageSize: 12,
    financialsTotal: 0,
    page: 1,
    pageSize: 12,
    total: 0,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    search: ''
};

const clients = createSlice({
    name: 'clientsState',
    initialState,
    reducers: {
        setClientsDetails(state, action) {
            state.clientsList = action.payload.data;
        },
        setSelectedClient(state, action) {
            state.selectedClient = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setToggleDeleteModal(state, action) {
            state.isDeleteModalOpen = action.payload;
        },
        setCountriesList(state, action) {
            state.countries = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setTotal(state, action) {
            state.total = action.payload;
        },
        setClientFinancials(state, action) {
            state.clientFinancials = action.payload;
        },
        setFinancialsPage(state, action) {
            state.financialsPage = action.payload;
        },
        setFinancialsPageSize(state, action) {
            state.financialsPageSize = action.payload;
        },
        setFinancialsTotal(state, action) {
            state.financialsTotal = action.payload;
        },
        setSortBy(state, action) {
            state.sortBy = action.payload;
        },
        setSortOrder(state, action) {
            state.sortOrder = action.payload;
        },
        setSearch(state, action) {
            state.search = action.payload;
        },
        setClientFormTab(state, action) {
            state.clientsFormTab = action.payload;
        },
        setIsFinancialsListLoading(state, action) {
            state.isFinancialsListLoading = action.payload;
        }
    }
});

export const { actions: ClientsReducerAction, name: ClientsReducerName, reducer: ClientsReducer } = clients;
