import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.DocumentState || initialState;

export const documentsListSelector = createSelector(selectDomain, (state) => state.documentsList);
export const documentsListLoadingSelector = createSelector(selectDomain, (state) => state.isLoading);
export const selectedDocumentSelector = createSelector(selectDomain, (state) => state.selectedDocument);
export const pageSelector = createSelector(selectDomain, (state) => state.page);
export const pageSizeSelector = createSelector(selectDomain, (state) => state.pageSize);
export const totalSelector = createSelector(selectDomain, (state) => state.total);
export const sortBySelector = createSelector(selectDomain, (state) => state.sortBy);
export const documentPropertiesListSelector = createSelector(selectDomain, (state) => state.propertiesList);
export const documentUnitsListSelector = createSelector(selectDomain, (state) => state.unitsList);
export const isDeleteModalOpenSelector = createSelector(selectDomain, (state) => state.isDeleteModalOpen);
export const searchSelector = createSelector(selectDomain, (state) => state.search);
export const documentFormIsLoadingSelector = createSelector(selectDomain, (state) => state.documentFormIsLoading);
export const sortOrderSelector = createSelector(selectDomain, (state) => state.sortOrder);
