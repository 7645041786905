import axiosServices from 'utils/axios';

export const GetClientsData = async (q?: string) => {
    try {
        const response = await axiosServices.get(`/client/allClients?${q}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const DownloadClientsList = async (q?: string) => {
    try {
        const response = await axiosServices.get(`/client/allClients?${q}`, {
            responseType: 'arraybuffer'
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const CreateClient = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post('/client/saveClient', data);
        return response;
    } catch (error) {
        return error;
    }
};

export const UpdateClient = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.put(`/client/update-client-details/${data?._id}`, data);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetClientDetailsById = async (id: string) => {
    try {
        const response = await axiosServices.get(`/client/client-details/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const DeleteClientById = async (id: string) => {
    try {
        const response = await axiosServices.delete(`/client/delete-client/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetAllCountries = async () => {
    try {
        const response = await axiosServices.get('/user/get-countries');
        return response;
    } catch (error) {
        return error;
    }
};

export const GetUnitsByClientId = async ({ id, query }: { id: string; query: string }) => {
    try {
        const response = await axiosServices.get(`/client/units/list/${id}?${query}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetUnitFinancialsByClientId = async ({ id, query }: { id: string; query: string }) => {
    try {
        const response = await axiosServices.get(`/client/client-financial/${id}?${query}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const UpdateClientPassword = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post(`/client/reset-password/${data?._id}`, data);
        return response;
    } catch (error) {
        return error;
    }
};
