import { memo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, AvatarGroup, Box, Button, Card, Divider, Grid, List, Typography, useMediaQuery } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// project imports
import NavItem from './NavItem';
import NavGroup from './NavGroup';
import color from 'assets/scss/_themes-vars.module.scss';
import menuItem from 'sidebar-items';
import useConfig from 'hooks/useConfig';

import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';

// types
import { NavItemType } from 'types';
import useAuth from 'hooks/useAuth';
import { gridSpacing } from 'store/constant';
import { sidepanelStateSelector } from 'store/sidepanel/selector';
import { useSelector } from 'react-redux';
import { mockImageHelper } from 'utils/helper';
import { UserTypeEnum } from 'types/user-profile';
// TODO: to make it optional(keep this comment)
// import { getFormattedUserName } from 'utils/common';

// ==============================|| SIDEBAR MENU LIST ||============================== //

export const sideBarItem = (loggedInUserType: string) => {
    switch (loggedInUserType) {
        case UserTypeEnum.ADMIN:
            return menuItem.items.filter((value) => value.id === 'admin');
        case UserTypeEnum.MANAGER:
            return menuItem.items.filter((value) => value.id === 'manager');
        case UserTypeEnum.AGENT:
            return menuItem.items.filter((value) => value.id === 'agent');
        case UserTypeEnum.CLIENT:
            return menuItem.items.filter((value) => value.id === 'client');
        case UserTypeEnum.AGENCY:
            return menuItem.items.filter((value) => value.id === 'agency');
        default:
            break;
    }
};

const MenuList = () => {
    const { loggedInUserType } = useAuth();
    const theme = useTheme();
    const { layout } = useConfig();
    const { drawerOpen } = useSelector(sidepanelStateSelector);
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    let remItems: NavItemType[] = [];
    let lastItemId: string;

    if (lastItem && lastItem < menuItem.items.length) {
        lastItemId = menuItem.items[lastItem - 1].id!;
        remItems = menuItem.items.slice(lastItem - 1, menuItem.items.length).map((item: any) => ({
            title: item.title,
            elements: item.children,
            icon: item.icon,
            ...(item.url && {
                url: item.url
            })
        }));
    }

    const sidebarItems = sideBarItem(String(loggedInUserType));

    const navItems = sidebarItems?.map((item: NavItemType) => {
        switch (item.type) {
            case 'group':
                if (item.url && item.id !== lastItemId) {
                    return (
                        <List key={item.id}>
                            <NavItem item={item} level={1} isParents />
                            {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && <Divider sx={{ py: 0.5 }} />}
                        </List>
                    );
                }

                return <NavGroup key={item.id} item={item} lastItem={lastItem!} remItems={remItems} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const userCheck = (loggedInUserType: any) => {
        switch (loggedInUserType) {
            case UserTypeEnum.AGENT:
                return true;
            case UserTypeEnum.CLIENT:
                return true;
            case UserTypeEnum.AGENCY:
                return true;
        }
    };

    return layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
        <>
            <Box {...(drawerOpen && { sx: { mt: 1.5 } })}>{navItems}</Box>
            {userCheck(loggedInUserType) && drawerOpen && (
                <Card
                    sx={{
                        p: 4,
                        background: color.grey50,
                        border: theme.palette.mode === 'dark' ? '1px solid transparent' : `1px solid${theme.palette.grey[100]}`,
                        '&:hover': {
                            borderColor: theme.palette.primary.main
                        },
                        width: '100%'
                    }}
                >
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            <Grid container display={'flex'} justifyContent={'space-between'} flexWrap={'nowrap'}>
                                <img
                                    src={mockImageHelper('1p7T4Phj3xjbvv8Ps3F0BXwAg1VcpKWMe')}
                                    alt="Client_Image"
                                    style={{
                                        borderRadius: '50%',
                                        width: '72px',
                                        height: '72px'
                                    }}
                                />
                                <Button
                                    style={{
                                        color: color.grey550,
                                        backgroundColor: 'transparent',
                                        zIndex: 1,
                                        height: '1.5rem',
                                        padding: 0,
                                        border: 0,
                                        display: 'flex',
                                        justifyContent: 'end',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <MoreHorizIcon fontSize="small" color="inherit" />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} display={'flex'} flexDirection={'column'} gap={'16px'}>
                            <Grid item xs={12}>
                                <Typography variant="h3" fontWeight={700}>
                                    {'Ana Nobre'}
                                </Typography>
                                <Typography variant="caption" color={color.grey550}>
                                    {'Property Manager'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display={'flex'} flexDirection={'column'} gap={'16px'}>
                                <Typography variant="h6" fontWeight={600}>
                                    {'+351 912 98 766'}
                                </Typography>
                                <Typography variant="h6" fontWeight={600}>
                                    {'ananobre@stonecapital.com'}
                                </Typography>
                                <AvatarGroup
                                    max={4}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Avatar alt="Remy Sharp" src={mockImageHelper('1YxfypBujGUASvzdfa81hruOH4NNnIrUh')} />
                                    <Avatar alt="Travis Howard" src={mockImageHelper('14CcsSlzYKnkvsdfSVLQYkXS07qSMvaQR')} />
                                </AvatarGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            )}
        </>
    ) : (
        <>{navItems}</>
    );
};

export default memo(MenuList);
